<template>
  <v-card flat class="mt-2">
    <v-btn
      color="success ml-2"
      @click="showAdd"
      :disabled="adding"
      fab
      small
      class="float-right"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-data-iterator
      class="mt-2"
      :items="items"
      item-key="title"
      hide-default-footer
      :search="search"
    >
      <template v-slot:default="{ items }">
        <v-row>
          <v-col
            v-for="item in items"
            :key="item.person.id"
            cols="12"
            sm="6"
            md="6"
            lg="4"
          >
            <v-card v-if="item.person.id > 0">
              <PersonItem v-if="item.person" :value="item.person" />
              <v-card-text>{{ item.comment }}</v-card-text>
              <v-divider></v-divider>
              <v-card-actions
                ><v-btn text block @click="unlock(item.person)" color="error"
                  >sperre aufheben</v-btn
                ></v-card-actions
              >
            </v-card>
            <v-card v-else>
              <v-banner color="success">
                <PersonInput
                  v-model="newPerson"
                  label="zu sperrende Person"
                  placeholder="Tippen um Suche zu starten"
                ></PersonInput>
              </v-banner>

              <PersonItem v-if="newPerson" :value="newPerson" />
              <v-list v-if="newPerson">
                <v-list-item>
                  <v-textarea
                    v-model="newComment"
                    outlined
                    label="Kommentar"
                  ></v-textarea>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-card-actions
                ><v-btn text @click="cancelAdding">abbruch</v-btn>
                <v-spacer></v-spacer
                ><v-btn
                  color="primary"
                  text
                  @click="lock"
                  :disabled="!newPerson || !newComment"
                  >sperre erfassen</v-btn
                ></v-card-actions
              >
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import PersonItem from "common/components/PersonItem";
import PersonInput from "common/components/PersonInput";

export default defineComponent({
  name: "ChangesLock",
  props: ["search"],
  components: { PersonItem, PersonInput },
  data: () => ({
    loading: false,
    newPerson: null,
    newComment: "",
    items: [],
    adding: false,
  }),

  methods: {
    async getData() {
      this.loading = true;
      this.items = await this.apiList({ resource: "change/lock" });
      this.loading = false;
    },
    showAdd() {
      this.items.unshift({
        person: { id: 0 },
      });
      this.adding = true;
    },
    async cancelAdding() {
      await this.getData();
      this.adding = false;
    },
    async lock() {
      await this.apiPost({
        resource: "change/lock",
        data: { person: this.newPerson, comment: this.newComment },
      });
      await this.getData();
      this.adding = false;
    },
    async unlock(person) {
      if (
        this.$root.confirm({
          message:
            "Soll die Sperre von " +
            person.name +
            " wirklich aufgehoben werden?",
          color: "info",
          icon: "mdi-lock-off",
        })
      ) {
        await this.apiDelete({ resource: "change/lock", id: person.id });
        this.getData();
      }
    },
  },
  async created() {
    this.getData();
  },
});
</script>
